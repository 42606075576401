import ng from 'angular';
import acpConnectedBanksTemplate from './templates/acp-connected-banks.html';

export class AcpConnectedBanksSectionComponentCtrl
  implements nsUtils.NsComponentController {
  constructor(nsComponentDecorator) {
    'ngInject';

    nsComponentDecorator(this, this);
  }
}

export const acpConnectedBanksSection: ng.IComponentOptions = {
  bindings: {}, // bindings advice: https://docs.angularjs.org/guide/component#component-based-application-architecture
  controller: AcpConnectedBanksSectionComponentCtrl,
  controllerAs: 'vm',
  require: {},
  template: acpConnectedBanksTemplate
};
